import { Column } from "@ant-design/plots";
import React, { useEffect } from "react";
import { REPORT_API } from "../../apis/api";
import { Card } from "antd";

const ScannerWiseReport = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getData = () => {
    REPORT_API.getScannerWiseReport().then((res) => {
      setData(res?.data?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const config = {
    data,
    xField: "_id",
    yField: "count",
    style: {
      fill: ({ type }) => {
        return "#f5bf42";
      },
    },
    legend: false,
  };

  return (
    <Card loading={loading} title={`Scanner wise scanning report`}>
      <Column {...config} />
    </Card>
  );
};

export default ScannerWiseReport;
