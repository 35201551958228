import { Col, Row } from "antd";
import React from "react";
import MonthlyScannedReport from "../dashboard/MonthlyScannedReport";
import CleanMismatchReport from "../dashboard/CleanMismatchReport";
import ScannerWiseReport from "./ScannerWiseReport";
const GraphReport = () => {
  return (
    <div>
      <Row gutter={[18, 18]}>
        <Col span={12}>
          <MonthlyScannedReport />
        </Col>
        <Col span={12}>
          <CleanMismatchReport />
        </Col>
        <Col span={24}>
          <ScannerWiseReport />
        </Col>
      </Row>
    </div>
  );
};

export default GraphReport;
